import React, { useEffect, useState } from 'react'
import axios from '../utils/axiosClient';
import { Button, Typography } from 'antd';

const ViewTextFile = ({ object, cloudfrontUrl }) => {
    const [data, setData] = useState('');
    const [canView, setCanView] = useState(true);

    useEffect(() => {
        axios({
            url: `${cloudfrontUrl}${object}`,
            method: 'GET',
            responseType: 'text'
        }).then(res => {
            setData(res.data);
        }).catch(err => {
            setCanView(false);
        })
        // fetch(`${cloudfrontUrl}${object}`)
    }, [object])

  return (
    <div style={{ textAlign: 'left', width: '80%', margin: 'auto', whiteSpace: 'pre-wrap', border: '1px solid rgba(0,0,0,0.4)', padding: '1em', borderRadius: '5px' }}>
        {canView && data}
        {!canView && <div>
            Cannot preview this file. Please download to view the file.
            <Typography.Link href={`${cloudfrontUrl}${object}`}>Download</Typography.Link>    
        </div>}
    </div>
  )
}

export default ViewTextFile