import { Table, theme } from 'antd';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';

let infiniteRef;


const VirtualTable = (props) => {
  const { columns, scroll, objectData } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  // const [itemsRendered, setItemsRendered] = useState(false);
  const itemRenderer = useRef(null);
  const heightRef = useRef(0);
  const widthRef = useRef(0);
  const scrollbarRef = useRef(0);
  const { token } = theme.useToken();
  const widthColumnCount = columns.filter(({ width }) => !width).length;
  let availableWidth = columns.reduce((acc, curr) => {
    if(curr.width) {
      return acc - curr.width
    } else {
      return acc
    }
  }, tableWidth)
  const mergedColumns = columns.map((column, index) => {
    // let availableWidth = tableWidth;
    if(tableWidth === 0) return columns
    if (column.width) {
      // availableWidth = availableWidth - column.width;
      return column;
    }
    const totalHeight = props.items.length * 50;
    // console.log('scrollRef', scrollbarRef.current, { totalHeight, tableHeight, prefix: objectData.Prefix })
    availableWidth = (totalHeight > 400 && index === 1)
      ? availableWidth - scrollbarRef.current - 1
      : availableWidth;
    return {
      ...column,
      width: availableWidth,
    };
  });
  // console.log('mergedollumens', mergedColumns)
  const gridRef = useRef();

  const { hasNextPage, isNextPageLoading, items, loadNextPage } = props;
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => console.log('load more requested') : e => {
    console.log('loading ore items');
    loadNextPage();
  };

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < items.length;
  
  // console.log({ itemCount, isNextPageLoading, isItemLoaded, items })

  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    Object.defineProperty(obj, 'scrollTop', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollTop;
        }
        return null;
      },
      set: (scrollTop) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollTop,
          });
        }
      },
    });
    return obj;
  });
  const resetVirtualGrid = () => {
    // console.log('useEffect reset indices', scrollbarRef.current)
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };
  useEffect(() => resetVirtualGrid, [tableWidth, objectData.Prefix]);

  // useEffect(() => {
  //   console.log('useEffect', widthRef.current, heightRef.current)
  //   if(heightRef.current !== tableHeight) {
  //     setTableHeight(heightRef.current);
  //   }
  //   if(widthRef.current !== tableWidth) {
  //     setTableHeight(widthRef.current);
  //   }
  // }, [heightRef.current, widthRef.current])

  useEffect(() => {
    let height = (props.items.length * 50)
    if(height > 400) {
      height = 400;
    } else {

    }
    setTableHeight(height);
    // if(props.items.length < 10) {
    //   setTableHeight()
    // } else if(props.items.length > 10 && height > 400) {
    //   setTableHeight(400);
    // } else {
    //   setTableHeight(height);
    // }
  }, [props.items.length])

  // console.log('columns', props.columns);
  return (
    <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            // height={height}
            // width={width}
          >
            {({ onItemsRendered, ref }) => {
              // gridRef.current = ref.;
              // setItemsRendered(onItemsRendered)
              // console.log('onItemsRendered', onItemsRendered.toString());
              // infiniteRef = ref;
              // itemRenderer.current = onItemsRendered;
              // console.log('mergedColumns', mergedColumns);
              const newItemsRendered = (gridData) => {
                const useOverscanForLoading = true;
                const {
                  visibleRowStartIndex,
                  visibleRowStopIndex,
                  visibleColumnStopIndex,
                  overscanRowStartIndex,
                  overscanRowStopIndex,
                  overscanColumnStopIndex
                } = gridData;

                const startRow =
                  useOverscanForLoading || true
                    ? overscanRowStartIndex
                    : visibleRowStartIndex;
                const endRow =
                  useOverscanForLoading || true
                    ? overscanRowStopIndex
                    : visibleRowStopIndex;

                const visibleStartIndex = startRow;
                const visibleStopIndex = endRow;

                onItemsRendered({
                  //call onItemsRendered from InfiniteLoader so it can load more if needed
                  visibleStartIndex,
                  visibleStopIndex
                });
              };
              return (
                <ResizeObserver
                  onResize={({ width, height }) => {
                    // console.log('resized', width)
                    setTableWidth(width);
                    // if(props.dataSource.length < 10) {
                    //   setTableHeight(props.dataSource.length * 50 + 70)
                    // } else if(props.dataSource.length > 10 && height > 400) {
                    //   setTableHeight(400);
                    // } else {
                    //   setTableHeight(height);
                    // }
                  }}
                >
                {/* <AutoSizer>
                  {({ height, width }) => {
                    console.log('autoSizer', { height, width })
                    return ( */}
                  <Table
                {...props}
                // ref={ref}
                rowSelection={{
                  type: 'radio'
                }}
                // style={{ height, width }}
                className="virtual-table"
                columns={mergedColumns}
                pagination={false}
                components={{
                  // body: renderProps => <RenderVirtualList {...renderProps} width={props.width} height={height} onItemsRendered={onItemsRendered} />
                  // body: props => renderVirtualList(...props, height, width, onItemsRendered, ref),
                  body: (rawData, { scrollbarSize, ref: antRef, onScroll }) => {
                    antRef.current = connectObject;
                    const totalHeight = rawData.length * 50;
                    scrollbarRef.current = scrollbarSize;
                    // setScrollbarsize(scrollbarSize);
                    // console.log('inner', height, width);
                    // if(!height) {
                    //   height = 600;
                    // }
                    // if(!width) {
                    //   width = 600;
                    // }
                    return (
                      <Grid
                        ref={grid => {
                          // refInfinite = grid;
                          ref(grid);
                          gridRef.current = grid; 
                          // antRef.current = grid;
                        }}
                        className="virtual-grid"
                        columnCount={mergedColumns.length}
                        columnWidth={(index) => {
                          const { width } = mergedColumns[index];
                          return width;
                        }}
                        height={tableHeight}
                        rowCount={itemCount}
                        rowHeight={() => 50}
                        width={tableWidth}
                        itemKey={({ columnIndex, rowIndex }) => {
                          // console.log('data key', { rawData, rowIndex, data: rawData[rowIndex] })
                          return `${rawData[rowIndex - 1]?.key || rowIndex}-${columnIndex}`
                        }}
                        overscanRowCount={5}
                        // onItemsRendered={e => {
                        //   // console.log('items rendered value', onItemsRendered(e), e, itemCount);
                        //   // if(!hasNextPage) return
                        //   if(e.visibleRowStopIndex >= (itemCount - 2) && items.length !== 144 && hasNextPage) return loadMoreItems();
                        //   e.visibleStartIndex = e.visibleRowStartIndex;
                        //   e.visibleStopIndex = e.visibleRowStopIndex;
                        //   console.log('iiii', e)
                        //   // onItemsRendered(e);
                        // }}
                        onItemsRendered={newItemsRendered}
                        onScroll={({ scrollLeft }) => {
                          resetVirtualGrid();
                          onScroll({
                            scrollLeft,
                          });
                        }}
                      >
                        {({ columnIndex, rowIndex, style }) => {
                            // console.log(rawData[rowIndex][mergedColumns[columnIndex].dataIndex], { rawData, rowIndex, mergedColumns, columnIndex })
                            // console.log('indeex', rowIndex, items)
                            let content, selected;
                            const record = rawData[rowIndex];
                            const { selectedObject, setSelectedObject, handleObjectLoad, handleFileView } = props;
                            if (!isItemLoaded(rowIndex)) {
                              content = "Loading...";
                            } else {
                              const dataIndex = mergedColumns[columnIndex].dataIndex;
                              content = mergedColumns[columnIndex].render ? mergedColumns[columnIndex].render(record[dataIndex], record) : record[dataIndex]
                              if(typeof selectedObject === 'string') {
                                selected = record.key === selectedObject;
                              } else {
                                selected = selectedObject && selectedObject.indexOf(record.Key) !== -1
                              }
                            }
                          return (
                          <div
                            className={classNames('virtual-table-cell', {
                              'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                            })}
                            style={{
                              ...style,
                              boxSizing: 'border-box',
                              padding: token.padding,
                              borderBottom: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
                              background: selected ? '#e6f4ff' : rowIndex % 2 ? '#fafafa' : token.colorBgContainer,
                            }}
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              if(record.DisplayKey === 'Load More') return;
                              // record.key = record.Key ? record.Key : record.Prefix
                              if(selectedObject && typeof selectedObject !== 'string') {
                                const newKeys = [...selectedObject];
                                if (newKeys.indexOf(record.key) >= 0) {
                                  newKeys.splice(newKeys.indexOf(record.key), 1);
                                } else if(record.head) {
                                  newKeys.push(record.key);
                                }
                                setSelectedObject(newKeys);
                              } else {
                                setSelectedObject(record.key)
                              }
                            }}
                            onDoubleClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              if(record.key && record.key[record.key.length - 1] === '/') {
                                setSelectedObject(record.key);
                                handleObjectLoad(record.DisplayKey);
                              } else {
                                setSelectedObject(record.key);
                                handleFileView(record.DisplayKey);
                              }
                            }}
                          >
                            {/* {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]} */}
                            {content}
                          </div>
                        )}}
                      </Grid>
                    );
                  },
                }}
              />
               {/* )
                  }} */}
          </ResizeObserver>)}}
      </InfiniteLoader>
  );
};

export default VirtualTable;