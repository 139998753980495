import { Radio, Table } from 'antd'
import React from 'react'
import Loader from './Loader'
import moment from 'moment'

const BucketList = ({ buckets, selectedBucket, setSelectedBucket, loadBucket }) => {

  const columns = [
    // {
    //   title: 'Select',
    //   dataIndex: '',
    //   key: 'select',
    //   render: (record) => <Radio checked={selectedBucket === record.Name} onChange={e => setSelectedBucket(record.Name)} />
    // },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'name'
    },
    {
      title: 'Created At',
      dataIndex: 'CreationDate',
      key: 'created',
      render: (date) => moment(date).format('lll')
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.Name === 'Disabled User',
      // Column configuration not to be checked
      name: record.Name,
    }),
    type: 'radio',
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedBucket ? [selectedBucket] : []
  };

  return (
    <div>
        <h2 style={{ marginLeft: '2em' }}>Buckets</h2>
        {/* {buckets.length === 0 && <Loader />} */}
        <div style={{ padding: '1em 2em' }}>
          {/* {buckets.map(bucket => (
              <div key={bucket.Name} style={{ display: 'flex', gap: '1em', alignitems: 'center' }}>
                <Radio checked={selectedBucket === bucket.Name} onChange={e => setSelectedBucket(bucket.Name)} />
                <p style={{ cursor: 'pointer' }}>
                    <strong>Name:</strong> {bucket.Name}
                </p>
                <p>{moment(bucket.CreationDate).format('lll')}</p>
              </div>
          ))} */}
          <Table 
            columns={columns} 
            dataSource={buckets} 
            pagination={{ hideOnSinglePage: true }} 
            rowSelection={{ ...rowSelection }}
            rowKey={record => record.Name}
            size='middle' 
            onRow={(record, rowIndex) => ({
              onClick: e => setSelectedBucket(record.Name),
              onDoubleClick: e => {
                if(record.Name) {
                    setSelectedBucket(record.Name);
                    loadBucket(undefined, record.Name);
                }
            }
            })}
          />
        </div>
    </div>
  )
}

export default BucketList