import React, { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Typography } from 'antd';
import mime from 'mime';
import appConfig from '../appConfig';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import ImageViewer from './ImageViewer/ImageViewer';
import ViewTextFile from './ViewTextFile';
// import FileViewer from 'react-file-viewer';

const cloudfrontUrl = appConfig.cloudfrontUrl;

const FileViewerComponent = ({
  object,
  setObject,
  objectList,
  open,
  setOpen,
}) => {
  const [fileType, setFileType] = useState(undefined);
  const [mimeType, setMimeType] = useState(undefined);
  const [ext, setExt] = useState(null);
  const [head, setHead] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(false);

  // console.log('file', object)

  useEffect(() => {
    async function getFileType() {
      const url = `${cloudfrontUrl}${object}`;
      const mime_type = mime.getType(url);
      const ext = mime.getExtension(mime_type);
      const head = objectList.find((o) => o.key === object).head;
      const preview = objectList.find((o) => o.key === object).preview;
      // const response = await fetch(url, {
      //     method: 'GET'
      // });
      // const blob = await response.blob();
      if (!mime_type) return;
      console.log('mimeType', mime_type);
      const type = mime_type.split('/')[0];
      console.log('fileType', type);
      console.log('ext', ext);
      setFileType(type);
      setMimeType(mime_type);
      setExt(ext);
      setHead(head);
      setPreview(preview);
    }

    getFileType();
  }, [object]);

  useEffect(() => {
    const toggleOpen = (e) => {
      if (e.key.toLowerCase() === 'p') {
        e.preventDefault();
        e.stopPropagation();
        setOpen((prev) => !prev);
      } else if (e.key.toLowerCase() === 'x') {
        e.preventDefault();
        e.stopPropagation();
        setObject(null);
      }
    };
    document.addEventListener('keydown', toggleOpen);
    return () => {
      document.removeEventListener('keydown', toggleOpen);
    };
  }, []);

  return (
    <div>
      <Drawer
        open={open}
        placement="bottom"
        keyboard
        height={'100%'}
        width={'100%'}
        title={
          <Typography.Title
            level={4}
            onClick={(e) => setOpen(false)}
            style={{ margin: '0 auto', cursor: 'pointer' }}
          >
            {object}
          </Typography.Title>
        }
        onClose={(e) => setOpen(false)}
        // bodyStyle={{ overflowX: 'hidden' }}
      >
        {(fileType === 'video' || fileType === 'audio') && (
          <VideoPlayer
            object={object}
            open={open}
            head={head}
            preview={preview}
            cloudfrontUrl={cloudfrontUrl}
            type={mimeType}
          />
        )}
        {fileType === 'image' && (
          <ImageViewer
            object={object}
            open={open}
            head={head}
            cloudfrontUrl={cloudfrontUrl}
            type={mimeType}
          />
        )}
        {/* {fileType === "audio" && <h2>Audio File</h2>} */}
        {/* {(ext === "pdf" || ext === 'docx') && !error && <FileViewer filePath={`${cloudfrontUrl}${object}`} fileType={ext} onError={e => setError(true)} />} */}
        {(fileType === 'application' || fileType === 'text') && (
          <ViewTextFile
            object={object}
            open={open}
            cloudfrontUrl={cloudfrontUrl}
            type={mimeType}
          />
        )}
        {!fileType && (
          <div>
            Cannot preview this file. Please download to view the file. <br />
            <Typography.Link
              href={`${cloudfrontUrl}${object}`}
              style={{ marginTop: '1em' }}
            >
              Download
            </Typography.Link>
          </div>
        )}
      </Drawer>
      {!open && (
        <div
          className="file-footer"
          onClick={(e) => setOpen(true)}
          style={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            height: '5em',
            padding: '1em',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: '9999999',
            backgroundColor: '#fff',
            cursor: 'pointer',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px 10px',
          }}
        >
          <Typography.Title level={4} ellipsis style={{ margin: '0 auto' }}>
            {object}
          </Typography.Title>
          <div style={{ display: 'flex', gap: '1em' }}>
            <KeyboardArrowUpIcon
              style={{ cursor: 'pointer' }}
              onClick={(e) => setOpen(true)}
            />
            <CloseIcon
              style={{ cursor: 'pointer' }}
              onClick={(e) => setObject(null)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileViewerComponent;
