import React, { useEffect, useMemo, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Radio,
  Table,
  Upload,
  Dropdown,
  Checkbox,
  Typography,
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { humanFileSize } from '../utils/utilFunctions';
import VirtualTable from './VirtualTable';
import { uniq } from 'lodash';

const ObjectsList = ({
  objectData,
  selectedBucket,
  selectedObject,
  setSelectedObject,
  loadBucket,
  loadBucketHead,
  handleUpload,
  handleDelete,
  after,
  handleCopy,
  downloadObject,
  viewFile,
  object,
  objectsLoading,
  objectList,
  setObjectList,
  loading,
}) => {
  const [selectionType, setSelectionType] = useState('radio');
  const [token, setToken] = useState(null);

  const currObjects = useMemo(() => {
    let objects = [];
    // if(objectData.isTruncated) {
    //   objects = uniq(objectList);
    // }
    if (objectData.CommonPrefixes) {
      objects = objects.concat(
        objectData.CommonPrefixes.filter(
          (t) => t.Key !== objectData.Prefix
        ).map((o) => {
          o.DisplayKey = o.Prefix.replace(objectData.Prefix, '');
          o.key = o.Key ? o.Key : o.Prefix ? o.Prefix : o.key;
          return o;
        })
      );
    }
    if (objectData.Contents) {
      objects = objects.concat(
        objectData.Contents.filter((t) => t.Key !== objectData.Prefix).map(
          (o) => {
            o.DisplayKey = o.Key.replace(objectData.Prefix, '');
            if (o.DisplayKey === '') {
              o.DisplayKey = o.Key;
            }
            o.key = o.Key ? o.Key : o.Prefix ? o.Prefix : o.key;
            return o;
          }
        )
      );
    }
    // console.log('objects before', objects);
    // objects = objects.filter(o => o.DisplayKey !== 'Load More');
    // console.log('objects after'. objects)
    // if(objectData.IsTruncated) {
    //   const loadMore = { DisplayKey: 'Load More', key: objectData.NextContinuationToken }
    //   objects.push(loadMore)
    // }
    return objects;
  }, [objectData]);

  useEffect(() => {
    // return objectData.Contents ? objectData.Contents : objectData.CommonPrefixes ? objectData.CommonPrefixes : []
    if (!token) {
      setObjectList([...currObjects]);
      if (objectData.IsTruncated) {
        setToken(objectData.NextContinuationToken);
      }
    } else if (token && objectData.ContinuationToken === token) {
      setObjectList((prev) => [
        ...prev.filter((o) => o.DisplayKey !== 'Load More').concat(currObjects),
      ]);
      if (objectData.IsTruncated) {
        setToken(objectData.NextContinuationToken);
      }
    } else if (token && !objectData.IsTruncated) {
      setObjectList([...currObjects]);
      setToken(undefined);
    } else {
      // console.log('objectsList insied', { objectData, currObjects, objectList, token })
    }
    // if(objectData.NextContinuationToken === token) return;
    // console.log('set', { objectData, token })
    // // let objects =
    // if(objectData.IsTruncated && objectData.ContinuationToken === token) {
    //   setObjectList(prev => [...prev.filter(o => o.DisplayKey !== 'Load More').concat(currObjects)]);
    // } else {
    //   setObjectList([...currObjects])
    // }
    // setToken(objectData.NextContinuationToken);
  }, [
    objectData.IsTruncated,
    objectData.NextContinuationToken,
    token,
    currObjects,
  ]);

  // console.log('objectsList', { objectData, currObjects, objectList, token })

  const items = useMemo(() => {
    // console.log('data memo', objectData);
    if (isEmpty(objectData)) {
      return [];
    }
    if (objectData?.Prefix === '') {
      // console.log('reaching here')
      return [
        {
          title: <strong className="breadcrumb-item">{selectedBucket}</strong>,
          onClick: () => {
            loadBucket();
            setSelectedObject(null);
          },
        },
      ];
    } else if (objectData?.Prefix !== '') {
      const items = [
        {
          title: <strong className="breadcrumb-item">{selectedBucket}</strong>,
          onClick: () => {
            loadBucket();
            setSelectedObject(null);
          },
        },
      ];
      const splits = objectData.Prefix.split('/').filter((t) => t !== '');
      const length = splits.length;
      splits.forEach((i, index) => {
        const prefix =
          objectData.Prefix.split('/')
            .slice(0, index + 1)
            .join('/') + '/';
        // console.log({index, length, splits})
        items.push({
          title: (
            <strong
              className={`breadcrumb-item ${
                index + 1 === length ? 'disabled' : ''
              }`}
            >
              {i}
            </strong>
          ),
          onClick: () => {
            loadBucket(prefix);
            setSelectedObject(null);
          },
        });
      });
      items[items.length - 1].onClick = () => {};
      if (document.getElementsByClassName('breadcrumb-item').length !== 0) {
        document.getElementsByClassName('breadcrumb-item')[
          document.getElementsByClassName('breadcrumb-item').length - 1
        ].classList.add = 'disabled';
      }
      return items;
    }
    // console.log('hello', objectData)
    // return objectData.Prefix.split('/').map(item => ({ title: <span>{item}</span> }))
    // return [];
  }, [objectData]);

  //   console.log('objct', items)

  const handleObjectLoad = (object) => {
    const objectKey = typeof object === 'string' ? object : selectedObject;
    loadBucket(`${objectData.Prefix}${objectKey}`);
  };

  const handleObjectHeadLoad = () => {
    const Key = objectList.find((o) => o.key === selectedObject).Key;
    loadBucketHead(Key);
  };

  const handleDownload = (flag) => {
    const Key = objectList.find((o) => o.DisplayKey === selectedObject).Key;
    if (typeof flag === 'boolean') {
      downloadObject(Key, true);
    } else {
      downloadObject(Key);
    }
  };

  const handleFileView = () => {
    const Key = objectList.find((o) => o.key === selectedObject).Key;
    // playVideo(Key);
    viewFile(Key);
  };

  const handleSelectionType = () => {
    const newType = selectionType === 'radio' ? 'checkbox' : 'radio';
    setSelectionType(newType);
    let newObj = null;
    if (newType === 'radio') {
      newObj =
        typeof selectedObject === 'object'
          ? selectedObject[selectedObject.length - 1]
          : null;
    } else {
      newObj = selectedObject ? [selectedObject] : null;
    }
    setSelectedObject(newObj);
  };

  useEffect(() => {
    if (!selectedObject) {
      setSelectionType('radio');
    } else if (typeof selectedObject === 'string') {
      setSelectionType('radio');
    } else {
      setSelectionType('checkbox');
    }
  }, [selectedObject]);

  const columns = [
    {
      title: 'Select',
      dataIndex: '',
      key: 'select',
      render: (_, record) => {
        if (selectionType === 'radio') {
          return (
            <Radio
              checked={selectedObject === record.key}
              onChange={(e) => setSelectedObject(record.key)}
            />
          );
        } else {
          return (
            <Checkbox
              checked={
                selectedObject && selectedObject.indexOf(record.key) !== -1
              }
              onChange={(e) => {
                const newKeys = [...selectedObject];
                if (newKeys.indexOf(record.key) >= 0) {
                  newKeys.splice(newKeys.indexOf(record.key), 1);
                } else if (record.head) {
                  newKeys.push(record.key);
                }
                setSelectedObject(newKeys);
              }}
            />
          );
        }
      },
      width: 70,
    },
    {
      title: 'Name',
      dataIndex: 'DisplayKey',
      key: 'name',
      // width: 240,
      render: (text, row) =>
        text === 'Load More' ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              loadBucket(objectData.Prefix);
            }}
          >
            Load More
          </Button>
        ) : (
          <Typography.Text ellipsis={{ tooltip: true }}>
            {decodeURIComponent(text)}
          </Typography.Text>
        ),
    },
    {
      title: 'Type',
      dataIndex: 'head',
      key: 'contentType',
      render: (head) => (head ? head.ContentType : 'Folder'),
      width: 200,
    },
    {
      title: 'Size',
      dataIndex: 'Size',
      key: 'size',
      render: (size) => (size ? humanFileSize(size) : ''),
      width: 150,
    },
    {
      title: 'Storage',
      dataIndex: 'StorageClass',
      key: 'storage',
      width: 150,
    },
    {
      title: 'Last Modified',
      dataIndex: 'LastModified',
      key: 'created',
      render: (date) => (date ? moment(date).format('lll') : ''),
      width: 200,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
      // if(record.DisplayKey === 'Load More') return;
      // console.log('type', typeof selectedRowKeys, selectionType);
      if (selectionType === 'checkbox') {
        const index = selectedRowKeys.indexOf('Load More');
        if (index !== -1) {
          selectedRowKeys.splice(index, 1);
        }
      } else {
        if (selectedRowKeys[0] === 'Load More') {
          selectedRowKeys = null;
        } else {
          selectedRowKeys = selectedRowKeys[0];
        }
      }
      // console.log()
      setSelectedObject(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.DisplayKey === 'Disabled User',
      // Column configuration not to be checked
      name: record.DisplayKey,
    }),
    type: selectionType,
    preserveSelectedRowKeys: true,
    selectedRowKeys:
      typeof selectedObject === 'string'
        ? [selectedObject]
        : selectedObject
        ? selectedObject
        : [],
  };

  const buttonItems = [
    {
      key: '1',
      label: 'Load',
      disabled:
        !selectedObject ||
        (selectedObject && selectedObject[selectedObject.length - 1] !== '/'),
      onClick: handleObjectLoad,
    },
    {
      key: '2',
      label: 'Load Head',
      disabled:
        !selectedObject ||
        (selectedObject &&
          typeof selectedObject === 'string' &&
          selectedObject[selectedObject.length - 1] === '/'),
      onClick: handleObjectHeadLoad,
    },
    {
      key: '3',
      label: 'Copy/Move',
      disabled:
        !selectedObject ||
        (selectedObject &&
          typeof selectedObject === 'string' &&
          selectedObject[selectedObject.length - 1] === '/'),
      onClick: () => handleCopy(true),
    },
    {
      key: '4',
      label: 'Download',
      disabled:
        !selectedObject ||
        (selectedObject &&
          typeof selectedObject === 'string' &&
          selectedObject[selectedObject.length - 1] === '/'),
      onClick: handleDownload,
    },
    {
      key: '5',
      label: 'Download As',
      disabled:
        !selectedObject ||
        (selectedObject &&
          typeof selectedObject === 'string' &&
          selectedObject[selectedObject.length - 1] === '/'),
      onClick: (e) => handleDownload(true),
    },
    {
      key: '6',
      label: 'View File',
      disabled:
        !selectedObject ||
        (selectedObject &&
          typeof selectedObject === 'string' &&
          selectedObject[selectedObject.length - 1] === '/'),
      onClick: (e) => handleFileView(selectedObject),
    },
  ];

  const handleClick = (e) => {
    // console.log(e);
  };

  return (
    <div style={object ? { marginBottom: '5em' } : {}}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          gap: '2em',
          alignItems: 'center',
          margin: '0 2em',
        }}
      >
        <h2>Objects</h2>
        <Breadcrumb items={items} />
        <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
          {/* <Button danger disabled={!selectedBucket || (selectedObject && selectedObject[selectedObject.length - 1] !== '/')} onClick={handleDeleteFolder}>Delete Folder</Button> */}
          <Button onClick={(e) => setSelectionType(handleSelectionType)}>
            Toggle
          </Button>
          <Button
            danger
            disabled={!selectedBucket || !selectedObject}
            onClick={handleDelete}
          >
            Delete
          </Button>
          {/* {selectionType === 'radio' && <Button disabled={} onClick={e => handleCopy(true)}>Copy/Move</Button>} */}
          <Button disabled={!selectedBucket} onClick={handleUpload}>
            Upload
          </Button>
          {/* <Button onClick={onCancel}>Cancel Upload</Button> */}
          {/* {selectionType === 'radio' && <Button>Load Head</Button>} */}
          {/* {selectionType === 'radio' && <Button  onClick={}>Load</Button>} */}
          <Dropdown.Button
            menu={{
              items: buttonItems,
              disabled: selectionType === 'checkbox',
            }}
          >
            Actions
          </Dropdown.Button>
        </div>
      </div>
      {/* {buckets.length === 0 && <Loader />} */}
      <div style={{ padding: '1em 2em', display: 'flex', width: '100%' }}>
        {/* {objects.map(object => (
              <div key={object.DisplayKey} style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                <Radio checked={selectedObject === object.DisplayKey} onChange={e => setSelectedObject(object.DisplayKey)} />
                <p style={{ cursor: 'pointer' }}>
                    <strong>Name:</strong> {object.DisplayKey}
                </p>
                <p>{moment(object.LastModified).format('lll')}</p>
              </div>
          ))} */}
        {/* <div style={{ height: '400px', maxHeight: '400px', flex: '1 1' }}> */}
        <VirtualTable
          columns={columns}
          objectData={objectData}
          hasNextPage={objectData.IsTruncated}
          isNextPageLoading={objectsLoading.current}
          loadNextPage={(e) => loadBucket(objectData.Prefix)}
          items={objectList}
          rowSelection={{ ...rowSelection }}
          // click props
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          handleObjectLoad={handleObjectLoad}
          handleFileView={handleFileView}
          rowKey={(record) => record.key}
          size="middle"
          dataSource={objectList}
          scroll={{
            x: '100%',
            y: 100,
          }}
          // footer={after.length !== 0 ? () => (<div style={{ display: 'flex', gap: '1em' }}>
          //   <Button disabled={(after && after.length !== 0 && after[after.length - 1].after !== '') ? false : true} onClick={e => loadBucket(objectData.Prefix, undefined, 'previous')}>Previous</Button>
          //   <Button disabled={!objectData.NextContinuationToken} onClick={e => loadBucket(objectData.Prefix, undefined, 'next')}>Next</Button>
          // </div>) : null}
          pagination={false}
          onRow={(record, rowIndex) => ({
            onClick: (e) => {
              if (record.DisplayKey === 'Load More') return;
              // record.key = record.Key ? record.Key : record.Prefix
              if (selectedObject && typeof selectedObject !== 'string') {
                const newKeys = [...selectedObject];
                if (newKeys.indexOf(record.key) >= 0) {
                  newKeys.splice(newKeys.indexOf(record.key), 1);
                } else if (record.head) {
                  newKeys.push(record.key);
                }
                setSelectedObject(newKeys);
              } else {
                setSelectedObject(record.key);
              }
            },
            onDoubleClick: (e) => {
              if (record.DisplayKey === 'Load More') return;
              // record.key = record.Key ? record.Key : record.Prefix
              if (record.key && record.key[record.key.length - 1] === '/') {
                setSelectedObject(record.key);
                handleObjectLoad(record.DisplayKey);
              } else {
                setSelectedObject(record.key);
                handleFileView(record.DisplayKey);
              }
            },
          })}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default ObjectsList;
