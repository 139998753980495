import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Input,
  Layout,
  Modal,
  Radio,
  Space,
  Upload,
} from 'antd';
import './App.css';
import HeaderComponent from './components/Header';
import { useEffect, useRef, useState } from 'react';
import axios from './utils/axiosClient';
import BucketList from './components/BucketList';
import { toast } from 'react-toastify';
import ObjectsList from './components/ObjectsList';
import ReactJson from 'react-json-view';
import { Uploader } from './utils/Uploader';
import UploadModal from './components/UploadModal';
import Loader from './components/Loader';
import CopyModal from './components/CopyModal';
// import VideoPlayer from './components/VideoPlayer';
import FileViewer from './components/FileViewer';
import appConfig from './appConfig';
import UserOutlined from '@ant-design/icons/UserOutlined';
import AuthModal from './components/AuthModal';

const cloudfrontUrl = appConfig.cloudfrontUrl;

const { Header, Content, Footer } = Layout;

function App() {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [buckets, setBuckets] = useState([]);
  const [selectedBucket, setSelectedBucket] = useState(null);
  const [objectData, setObjectData] = useState({});
  const [selectedObject, setSelectedObject] = useState(null);
  const [objectHead, setObjectHead] = useState({});
  const [showHead, setShowHead] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [maxKeys, setMaxKeys] = useState(10);
  const objectsLoading = useRef(false);
  // const [previousToken, setPreviousToken] = useState(null);
  const [after, setAfter] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [object, setObject] = useState('The.Big.Bang.Theory.S06E01.HDTV.x264-LOL.mp4');
  const [object, setObject] = useState(null);
  const [open, setOpen] = useState(false);
  const [objectList, setObjectList] = useState([]);
  const [authModal, setAuthModal] = useState(false);

  const fetchBuckets = () => {
    axios
      .get('/api/s3/buckets')
      .then((res) => {
        // console.log(res.data);
        setBuckets(res.data.Buckets);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response?.data.message || err.message);
      });
  };

  const createBucket = (name) => {
    setLoading(true);
    axios
      .post('/api/s3/bucket', { name })
      .then((res) => {
        // console.log(res.data);
        fetchBuckets();
        toast.success('Bucket Created Successfully');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response?.data.message || err.message);
      });
  };

  const deleteBucket = () => {
    if (!selectedBucket) {
      toast.warn('No Bucket Selected');
      return;
    }
    setLoading(true);
    axios
      .delete(`/api/s3/bucket/${selectedBucket}`)
      .then((res) => {
        // console.log(res.data);
        fetchBuckets();
        setSelectedBucket(null);
        toast.success('Bucket Deleted Successfully');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response?.data.message || err.message);
        setSelectedBucket(null);
      });
  };

  const deleteObject = () => {
    if (!selectedObject) {
      toast.warn('No Object Selected');
      return;
    }
    setLoading(true);
    let key = selectedObject;
    console.log(key, selectedObject);
    if (typeof selectedObject !== 'string') {
      key = selectedObject.map((k) => objectData.Prefix + k);
    }
    axios
      .post(`/api/s3/bucket/${selectedBucket}/object/delete`, { key })
      .then((res) => {
        // console.log(res.data);
        loadBucket(objectData.Prefix, undefined, undefined, []);
        setSelectedObject(null);
        setAfter([]);
        // setObjectHead(res.data);
        // setShowHead(true)
        toast.success('Object Deleted Successfully');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response?.data.message || err.message);
        setSelectedObject(null);
      });
  };

  // Load with Pagination
  // const loadBucket = (prefixValue = "EMPTY_PREFIX", bucket, pageType, currAfter) => {
  //   setLoading(true);
  //   const currBucket = bucket ? bucket : selectedBucket
  //   const prefix = prefixValue !== '' ? prefixValue : "EMPTY_PREFIX"
  //   if(!currBucket) {
  //     toast.warn('No Bucket Selected');
  //     return;
  //   }
  //   const body = {
  //     name: currBucket,
  //     prefix,
  //     maxKeys
  //   }
  //   let newAfter = currAfter ? [...currAfter] : [...after]
  //   console.log('after', after)
  //   if(pageType === 'next' && objectData.IsTruncated) {
  //     const nextToken = after[after.length - 1].nextToken;
  //     body.nextToken = nextToken;
  //   }
  //   if(pageType === 'previous') {
  //     body.startAfter = after[after.length - 2]?.after;
  //     newAfter.pop();
  //   }
  //   console.log('body', { body, newAfter })
  //   axios.post('/api/s3/bucket/load', body).then(res => {
  //     console.log('object list', res.data);
  //     let contents = res.data.Contents ? res.data.Contents : [];
  //     if(res.data.CommonPrefixes) {
  //       contents = contents.concat(res.data.CommonPrefixes).filter(n => n.Key !== res.data.Prefix);
  //     }
  //     let oldContents = objectData.Contents ? objectData.Contents : [];
  //     if(objectData.CommonPrefixes) {
  //       oldContents = oldContents.concat(objectData.CommonPrefixes).filter(n => n.Key !== objectData.Prefix);
  //     }
  //     if(newAfter.length !== 0 && newAfter[0].prefix !== prefix && !res.data.IsTruncated) {
  //       console.log('empty', newAfter, prefix)
  //       setAfter([])
  //     } else if(contents && res.data.IsTruncated) {
  //       if(newAfter.length === 0 || newAfter[0].prefix !== prefix) {
  //         newAfter = [];
  //         console.log('difff 123', { objectData, data: res.data })
  //         const obj = { prefix, after: '', nextToken: res.data.NextContinuationToken };
  //         newAfter.push(obj);
  //       } else if(pageType && pageType !== 'previous') {
  //         console.log('difff', { objectData, data: res.data, oldContents })
  //         const key = oldContents[oldContents.length - 1].Key ? 'Key' : 'Prefix'
  //         const obj = { prefix, after: oldContents[oldContents.length - 1][key], nextToken: res.data.NextContinuationToken };
  //         newAfter.push(obj);
  //       }
  //       console.log('setting after', newAfter)
  //       setAfter(newAfter)
  //     } else if(!res.data.IsTruncated && !res.data.ContinuationToken) {
  //       console.log('reaching', res.data)
  //       setAfter([]);
  //     }
  //     setObjectData(res.data);
  //     setLoading(false);
  //   }).catch(err => {
  //     console.log(err);
  //     setLoading(false);
  //     toast.error(err.response?.data.message || err.message);
  //   })
  // }

  // Load Infinit Scroll
  const loadBucket = (prefixValue = 'EMPTY_PREFIX', bucket) => {
    // if(loading) return
    objectsLoading.current = true;
    if (objectData.IsTruncated) {
      // console.log('not setting loading', loading)
    } else {
      setLoading(true);
    }
    const currBucket = bucket ? bucket : selectedBucket;
    const prefix = prefixValue !== '' ? prefixValue : 'EMPTY_PREFIX';
    if (!currBucket) {
      toast.warn('No Bucket Selected');
      return;
    }
    const body = {
      name: currBucket,
      prefix,
      maxKeys,
    };
    if (objectData.IsTruncated && objectData.Prefix === prefix) {
      const nextToken = objectData.NextContinuationToken;
      body.nextToken = nextToken;
    }
    axios
      .post('/api/s3/bucket/load', body)
      .then((res) => {
        // console.log('object list', res.data);
        setObjectData(res.data);
        setLoading(false);
        objectsLoading.current = false;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response?.data.message || err.message);
      });
  };

  const copyObject = (data) => {
    setLoading(true);
    setCopyModal(false);
    axios
      .post('/api/s3/object/copy', data)
      .then((res) => {
        setLoading(false);
        toast.success(
          `Object ${data.copyType === 'copy' ? 'Copied' : 'Moved'} Successfully`
        );
        loadBucket(objectData.Prefix);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response?.data.message || err.message);
      });
  };

  const loadBucketHead = (Key = '') => {
    if (!selectedBucket) {
      toast.warn('No Bucket Selected');
      return;
    }
    setLoading(true);
    axios
      .post(`/api/s3/bucket/${selectedBucket}`, { key: Key })
      .then((res) => {
        // console.log(res.data);
        setObjectHead(res.data);
        setShowHead(true);
        setLoading(false);
        // fetchBuckets();
        // setSelectedBucket(null);
        toast.success('Object Head Loaded Successfully');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response?.data.message || err.message);
        // setSelectedBucket(null)
      });
  };

  const downloadObject = (Key = '', downloadAs) => {
    if (!selectedBucket) {
      toast.warn('No Bucket Selected');
      return;
    }

    if (!downloadAs) {
      const link = document.createElement('a');
      link.href = `${cloudfrontUrl}${Key}`;
      link.setAttribute('download', selectedObject); //or any other extension
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      Modal.confirm({
        title: 'Download as',
        content: (
          <div>
            <p>
              To choose your download destination, right-click the link below,
              then choose "Save link as..."
            </p>
            <a href={`${cloudfrontUrl}${Key}`} download target="_blank">
              {selectedObject}
            </a>
          </div>
        ),
        onOk() {},
        onCancel() {},
      });
    }
  };

  const viewFile = (key) => {
    // console.log('key', key);
    setObject(key);
    setOpen(true);
  };

  const getInfo = () => {
    if (!selectedBucket) {
      toast.warn('No Bucket Selected');
      return;
    }
    setLoading(true);
    axios
      .get(`/api/s3/bucket/${selectedBucket}`)
      .then((res) => {
        // console.log(res.data);
        setObjectHead(res.data);
        setShowHead(true);
        setLoading(false);
        // fetchBuckets();
        // setSelectedBucket(null);
        toast.success('Object Head Loaded Successfully');
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data.message || err.message);
        setLoading(false);
        // setSelectedBucket(null)
      });
  };

  // const deleteObjects = () => {
  //   if(!selectedObject) {
  //     toast.warn('No Object Selected');
  //     return;
  //   }
  //   const key = objectData.Prefix + selectedObject;
  //   axios.put(`/api/s3/bucket/${selectedBucket}/object/${key}`).then(res => {
  //     console.log(res.data);
  //     setObjectHead(res.data);
  //     setShowHead(true)
  //     // loadBucket(objectData.Prefix);
  //     // setSelectedObject(null);
  //     toast.success('Object Deleted Successfully');
  //   }).catch(err => {
  //     console.log(err);
  //     toast.error(err.response?.data.message || err.message);
  //     setSelectedObject(null)
  //   })
  // }

  useEffect(() => {
    if (!user) {
      return () => {};
    }
    fetchBuckets();
    const { bucket, prefix, selectedObject } = localStorage.getItem('s3-config')
      ? JSON.parse(localStorage.getItem('s3-config'))
      : { bucket: null, prefix: null, selectedObject: null };
    if (bucket && prefix) {
      setSelectedBucket(bucket);
      if (!objectsLoading.current) {
        loadBucket(prefix, bucket);
      }
      if (selectedObject) {
        setSelectedObject(selectedObject);
      }
    }
  }, [user]);

  useEffect(() => {
    if (selectedBucket && objectData.Prefix) {
      const obj = {
        bucket: selectedBucket,
        prefix: objectData.Prefix,
        selectedObject,
      };
      // console.log('setting storage', obj )
      localStorage.setItem('s3-config', JSON.stringify(obj));
    }
  }, [selectedBucket, objectData.Prefix, selectedObject]);

  useEffect(() => {
    // const fetchUser = async (token) => {
    //   const response = await axios.get(`/api/auth/user/${token}`);
    //   if(response.status === 200) {
    //     setUser(response.data);
    //     setUserLoading(false);
    //   } else {
    //     setUserLoading(false);
    //   }
    // }

    if (!user && userLoading) {
      const token = localStorage.getItem('s3-auth');
      if (!token) {
        setUserLoading(false);
      } else {
        setUser(true);
        setUserLoading(false);
      }
    }
  }, [user, userLoading]);

  const signupUser = async ({ name, email, password }) => {
    try {
      const response = await axios.post('/api/auth/signup', {
        name,
        email,
        password,
      });
      if (response.data?.token) {
        setUser(true);
        localStorage.setItem('s3-auth', response.data.token);
      } else {
        toast.error('Error in sign up. Please try again later.');
      }
    } catch (err) {
      toast.error('Error in sign up. Please try again later.');
    }
  };

  const loginUser = async ({ email, password }) => {
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      if (response.data?.token) {
        setUser(true);
        localStorage.setItem('s3-auth', response.data.token);
      } else {
        toast.error('Error in sign in. Please try again later.');
      }
    } catch (err) {
      toast.error('Error in sign in. Please try again later.');
    }
  };

  // console.log('loading', loading)

  if (!user && userLoading) {
    return <Loader />;
  }

  const items = [
    {
      key: '1',
      label: 'Sign In',
      onClick: () => setAuthModal(true),
    },
  ];

  if (!user && !userLoading) {
    return (
      <Layout
        className="app-container"
        style={{ display: 'flex', minHeight: '100vh' }}
      >
        <Header style={{ backgroundColor: 'transparent' }}>
          <Dropdown
            menu={{
              items,
            }}
          >
            <Avatar icon={<UserOutlined />} className="profile-dropdown" />
          </Dropdown>
        </Header>
        <Content
          style={{
            height: '100%',
            display: 'grid',
            placeItems: 'center',
            flex: 1,
          }}
        >
          Please Login to access the website.
        </Content>
        <AuthModal
          open={authModal}
          onClose={(e) => setAuthModal(false)}
          loginUser={loginUser}
          signupUser={signupUser}
        />
      </Layout>
    );
  }

  return (
    <Layout className="app-container">
      {loading && <Loader />}
      <Header style={{ backgroundColor: 'transparent' }}>
        <HeaderComponent
          createBucket={createBucket}
          deleteBucket={deleteBucket}
          loadBucket={loadBucket}
          getInfo={getInfo}
        />
      </Header>
      <Content>
        <BucketList
          buckets={buckets}
          selectedBucket={selectedBucket}
          setSelectedBucket={setSelectedBucket}
          loadBucket={loadBucket}
        />
        <Divider />
        <ObjectsList
          objectData={objectData}
          selectedBucket={selectedBucket}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          loadBucket={loadBucket}
          loadBucketHead={loadBucketHead}
          handleUpload={(e) => setUploadModal(true)}
          handleDelete={deleteObject}
          handleCopy={setCopyModal}
          downloadObject={downloadObject}
          viewFile={viewFile}
          after={after}
          object={object}
          objectsLoading={objectsLoading}
          loading={loading}
          objectList={objectList}
          setObjectList={setObjectList}
          // handleDeleteFolder={deleteObjects}
        />
        {/* {video && <VideoPlayer videoUrl={videoUrl} closeVideo={e => setVideo(false)} />} */}
        {object && (
          <FileViewer
            object={object}
            setObject={setObject}
            objectList={objectList}
            open={open}
            setOpen={setOpen}
          />
        )}
      </Content>
      <Modal
        open={showHead}
        centered
        closable
        onCancel={(e) => setShowHead(false)}
        width={700}
        footer={null}
      >
        {/* {JSON.stringify(objectHead)} */}
        <ReactJson
          src={objectHead}
          name={false}
          style={{
            border: '1px solid #eaedf2',
            backgroundColor: '#eaedf2',
            maxHeight: '50vh',
            overflow: 'auto',
            padding: '1em',
            borderRadius: '6px',
            marginTop: '2em',
          }}
          displayObjectSize={false}
          displayDataTypes={false}
          displayArrayKey={false}
        />
      </Modal>
      <UploadModal
        open={uploadModal}
        onClose={(e) => setUploadModal(false)}
        bucket={selectedBucket}
        prefix={objectData.Prefix}
        loadBucket={loadBucket}
      />
      <CopyModal
        open={copyModal}
        onClose={(e) => setCopyModal(false)}
        selectedBucket={selectedBucket}
        prefix={objectData.Prefix}
        selectedObject={selectedObject}
        copyObject={copyObject}
      />
    </Layout>
  );
}

export default App;
