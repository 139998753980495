import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Uploader } from '../utils/Uploader';
import { Button, Input, List, Modal, Typography, Upload } from 'antd';
import { humanFileSize } from '../utils/utilFunctions';
import { toast } from 'react-toastify';

const UploadModal = ({ open, onClose, bucket, prefix, loadBucket }) => {
  const [fileList, setFileList] = useState([]);
  const [uploader, setUploader] = useState(null);
  const [progress, setProgress] = useState({});
  const [index, setIndex] = useState(0);
  const [progressData, setProgressData] = useState(null);
  const [completeData, setCompleteData] = useState(null);
  const [page, setPage] = useState(1);

  const init = () => {
    const filename =
      fileList[index].originFileObj.webkitRelativePath !== ''
        ? fileList[index].originFileObj.webkitRelativePath
        : fileList[index].name;
    const videoUploaderOptions = {
      fileName: `${prefix}${filename}`,
      file: fileList[index].originFileObj,
      bucket,
    };

    const uploader = new Uploader(videoUploaderOptions);
    setUploader(uploader);
    uploader
      .onProgress(setProgressData)
      .onComplete(setCompleteData)
      .onError(onError)
      .start();
  };

  const onCancel = () => {
    if (uploader) {
      uploader.abort();
      // setFile(undefined)
    }
  };

  const startUpload = () => {
    uploader.start();
  };

  const onProgress = useCallback(
    ({ percentage, sent, total }) => {
      // to avoid the same percentage to be logged twice
      // if (newPercentage !== percentage) {
      // percentage = newPercentage
      // setProgress(prev => {
      //   prev[fileList[index].uid] = percentage;
      //   return prev;
      // })
      const obj = { percentage, sent, total };
      setProgress((prev) => ({ ...prev, [fileList[index].uid]: obj }));
      console.log(`${percentage}%`);
      // }
    },
    [index, fileList, uploader]
  );

  const onComplete = useCallback(() => {
    let newIndex = index + 1;
    if (newIndex < fileList.length) {
      let filename =
        fileList[newIndex].originFileObj.webkitRelativePath !== ''
          ? fileList[newIndex].originFileObj.webkitRelativePath
          : fileList[newIndex].name;
      // filename = encodeURIComponent(filename);
      uploader.file = fileList[newIndex].originFileObj;
      uploader.fileName = `${prefix}${filename}`;
      setIndex((prev) => prev + 1);
      uploader.startNext();
      setPage(Math.floor(newIndex / 3) + 1);
    } else {
      toast.success('Files Uploaded Successfully');
      loadBucket(prefix);
    }
  }, [index, fileList, uploader]);

  const onError = useCallback((error) => {
    console.error(error);
  }, []);

  useEffect(() => {
    if (uploader) {
    }
  }, [uploader, setProgressData, setCompleteData, onError]);

  useEffect(() => {
    if (progressData) {
      onProgress(progressData);
      setProgressData(null);
    }
  }, [progressData, setProgress, onProgress]);

  useEffect(() => {
    if (completeData) {
      onComplete();
      setCompleteData(null);
    }
  }, [completeData, uploader, onComplete]);

  const getInstance = () => {
    console.log('uploader', uploader.getInstance());
  };

  const getProgress = (item) => {
    const { percentage, sent, total } = progress[item.uid];
    return `${humanFileSize(sent)} / ${humanFileSize(total)} (${percentage}%)`;
  };

  const getTotalProgress = () => {
    const total = Object.keys(progress).reduce(
      (acc, curr) => acc + progress[curr].total,
      0
    );
    const sent = Object.keys(progress).reduce(
      (acc, curr) => acc + progress[curr].sent,
      0
    );
    const percentage = Math.round((sent / total) * 100);
    return `${humanFileSize(sent)} / ${humanFileSize(total)} (${percentage}%)`;
  };

  const handleUploadChange = (info) => {
    setFileList(info.fileList);

    const defaultProgress = {};
    info.fileList.forEach((file) => {
      defaultProgress[file.uid] = {
        percentage: 0,
        sent: 0,
        total: file.size,
      };
    });

    setProgress(defaultProgress);
  };

  const resetState = () => {
    setFileList([]);
    setUploader(null);
    setProgress({});
    setIndex(0);
    setProgressData(null);
    setCompleteData(null);
    setPage(1);
  };

  // console.log(fileList)

  return (
    <Modal
      open={open}
      centered
      closable
      onCancel={(e) => {
        onClose();
        resetState();
      }}
      width={800}
      onOk={(e) => {
        onClose();
        resetState();
      }}
      okText="Done"
      // footer={null}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1em',
          padding: '2em 0em',
        }}
      >
        {open && <Input defaultValue={prefix} addonBefore="Destination" />}
        <Upload
          beforeUpload={() => false}
          onChange={handleUploadChange}
          fileList={fileList}
          multiple
          showUploadList={false}
        >
          <Button>Upload Files</Button>
        </Upload>
        <Upload
          beforeUpload={() => false}
          onChange={handleUploadChange}
          directory
          showUploadList={false}
        >
          <Button>Upload Folder</Button>
        </Upload>
      </div>
      {fileList.length !== 0 && (
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto auto auto',
              gap: '1em',
              padding: '.5em 0',
              alignItems: 'center',
            }}
          >
            <Typography.Text>File List</Typography.Text>
            <Button onClick={getInstance}>Console Uploader</Button>
            <Button type="text" danger onClick={(e) => setFileList([])}>
              Clear Files
            </Button>
            <Button type="primary" onClick={init}>
              Confirm Upload
            </Button>
          </div>
          <List
            dataSource={fileList}
            bordered
            size="small"
            pagination={{
              onChange: (currPage) => {
                console.log(currPage);
                setPage(currPage);
              },
              style: { margin: '0.5em' },
              // hideOnSinglePage: true,
              pageSize: 3,
              position: 'bottom',
              align: 'end',
              current: page,
              showSizeChanger: false,
              size: 'small',
              showTotal: (total) => (
                <Typography.Text strong>
                  Total Files: {fileList.length}, Total Size:{' '}
                  {humanFileSize(
                    fileList.reduce((acc, curr) => acc + curr.size, 0)
                  )}{' '}
                  <Typography.Text type="success">
                    {getTotalProgress()}
                  </Typography.Text>
                </Typography.Text>
              ),
            }}
            renderItem={(item) => (
              <List.Item
                style={{ display: 'grid', gridTemplateColumns: '1fr 17em 5em' }}
              >
                <Typography.Text
                  ellipsis={{
                    tooltip:
                      item.originFileObj.webkitRelativePath !== ''
                        ? item.originFileObj.webkitRelativePath
                        : item.name,
                  }}
                >
                  {item.originFileObj.webkitRelativePath !== ''
                    ? item.originFileObj.webkitRelativePath
                    : item.name}
                </Typography.Text>
                <Typography.Text type="success">
                  Progress: {getProgress(item)}
                </Typography.Text>
                <Typography.Text>{humanFileSize(item.size)}</Typography.Text>
              </List.Item>
            )}
          />
        </div>
      )}
    </Modal>
  );
};

export default UploadModal;
