import { Input, Modal, Radio, Space } from 'antd';
import React, { useState } from 'react'

const AuthModal = ({ open, onClose, loginUser, signupUser }) => {
    const [authType, setAuthType] = useState('login');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // const handleCopy = () => {
    //     copyObject({ copyType, copyValue: copyValue + selectedObject?.replace(prefix, ''), sourceValue: `/${selectedBucket}/${selectedObject}` });
    //     setCopyType('copy');
    //     setCopyValue('');
    // }

    const handleSubmit = () => {
        if(authType === 'login') {
            loginUser({ email, password })
        } else {
            signupUser({ name, email, password });
        }
    }

  return (
    <Modal
        open={open}
        onCancel={onClose}
        width={500}
        centered
        closable
        onOk={handleSubmit}
      >
        <div style={{ display: 'grid', gap: '1em' }}>
            {/* <Radio.Group value={authType} onChange={e => setAuthType(e.target.value)}>
                <Radio.Button value="login">Login</Radio.Button>
                <Radio.Button value="signup">Signup</Radio.Button>
            </Radio.Group> */}
            {authType !== "login" && <div>
                <label>Name</label>
                <Input placeholder='Enter Name' value={name} onChange={e => setName(e.target.value)} />
            </div>}
            <div>
                <label>Email</label>
                <Input placeholder='Enter Email' value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div>
                <label>Password</label>
                <Input.Password placeholder='Enter Password' value={password} onChange={e => setPassword(e.target.value)} />
            </div>
        </div>
      
      </Modal>
  )
}

export default AuthModal