import { useEffect, useRef, useState } from 'react';

export const useVideoPlayer = ({
  videoRef,
  videoContainerRef,
  timelineContainerRef,
  thumbnailImgRef,
  previewImgRef,
  cloudfrontUrl,
  head,
  object,
  open,
  preview,
}) => {
  // const video = videoRef.current;
  // const videoContainer = videoContainerRef.current;
  const playing = useRef(false);
  const [theatreMode, setTheatreMode] = useState(false);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [miniPlayerMode, setMiniPlayerMode] = useState(false);
  const [volume, setVolume] = useState(1);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [captions, setCaptions] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isScrubbing, setIsScrubbing] = useState(false);
  // const [wasPaused, setWasPaused] = useState(false);
  const scrubRef = useRef(false);
  const wasPaused = useRef(false);
  const timeoutRef = useRef(null);

  const togglePlay = () => {
    // setPlaying(prev => !prev);
    const video = videoRef.current;
    const isPlaying =
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > video.HAVE_CURRENT_DATA;

    !isPlaying ? video.play() : video.pause();
  };

  const toggleTheatreMode = () => {
    setTheatreMode((prev) => !prev);
  };

  const toggleFullScreenMode = () => {
    if (document.fullscreenElement == null) {
      videoContainerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const toggleFullScreenClass = () => {
    if (document.fullscreenElement) {
      setFullScreenMode(true);
    } else {
      setFullScreenMode(false);
    }
  };

  const toggleMiniPlayerMode = () => {
    if (miniPlayerMode) {
      document.exitPictureInPicture();
    } else {
      videoRef.current.requestPictureInPicture();
    }
  };

  const enterMiniPlayerMode = () => {
    setMiniPlayerMode(true);
  };

  const leaveMiniPlayerMode = () => {
    setMiniPlayerMode(false);
  };

  const toggleMute = () => {
    if (volume > 0) {
      setVolume(0);
      videoRef.current.muted = true;
    } else {
      setVolume(videoRef.current.volume);
      videoRef.current.muted = false;
    }
  };

  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
    videoRef.current.volume = e.target.value;
  };

  const setVideoData = () => {
    const video = videoRef.current;
    console.log('duration', video.duration);
    setDuration(video.duration);
    setCurrentTime(video.currentTime);
  };

  const setVideoTime = () => {
    const time = videoRef.current.currentTime;
    const percent = time / videoRef.current.duration;
    console.log(percent, 'set time', time, duration);
    timelineContainerRef.current.style.setProperty(
      '--progress-position',
      percent
    );
    // videoRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const skip = (duration) => {
    videoRef.current.currentTime += duration;
    // setCurrentTime(videoRef.current.currentTime);
  };

  const toggleCaptions = () => {
    const captionsEl = videoRef.current.textTracks[0];
    if (captionsEl.mode === 'hidden' || captionsEl.mode === 'disabled') {
      captionsEl.mode = 'showing';
      setCaptions(true);
    } else {
      captionsEl.mode = 'hidden';
      setCaptions(false);
    }
  };

  const changePlaybackSpeed = () => {
    let currSpeed = videoRef.current.playbackRate + 0.25;
    if (currSpeed > 2) currSpeed = 0.25;
    videoRef.current.playbackRate = currSpeed;
    setPlaybackRate(currSpeed);
  };

  const handleTimelineUpdate = (e) => {
    const container = timelineContainerRef.current;
    const rect = container.getBoundingClientRect();
    const event = e.nativeEvent ? e.nativeEvent : e;
    const percent =
      Math.min(Math.max(0, event.x - rect.x), rect.width) / rect.width;
    let imgSrc;
    if (preview) {
      const previewImgNumber = Math.max(
        1,
        Math.floor(percent * Math.floor(duration / 30)) + 1
      );
      console.log(percent * Math.floor(duration / 30));
      //   imgSrc = `${cloudfrontUrl}thumbnails/${object}/preview${previewImgNumber}.jpg`;
      imgSrc = `${cloudfrontUrl}${object.replace(
        '/output.mp4',
        ''
      )}/thumbnails/preview${previewImgNumber}.jpg`;
      console.log({ imgSrc, duration, percent });
      previewImgRef.current.src = imgSrc;
    }
    container.style.setProperty('--preview-position', percent);
    // let scrubbing = localScrubbing ? localScrubbing : isScrubbing;
    if (scrubRef.current) {
      e.preventDefault();
      //   console.log('updating thumbnail ref')
      //   console.log('ref', videoContainerRef.current)
      if (preview) {
        thumbnailImgRef.current.src = imgSrc;
      }
      console.log('video container add', videoContainerRef.current.className);
      //   console.log('percent timeline', percent)
      container.style.setProperty('--progress-position', percent);
    }
  };

  const handleScrubbing = async (e) => {
    // if(scrubRef.current) return;
    console.log('scrub called', e.type);
    const container = timelineContainerRef.current;
    const rect = container.getBoundingClientRect();
    const event = e.nativeEvent ? e.nativeEvent : e;
    const percent =
      Math.min(Math.max(0, event.x - rect.x), rect.width) / rect.width;
    scrubRef.current = (e.buttons & 1) === 1;
    console.log(e.buttons, e, scrubRef);
    // setIsScrubbing(localScrubbing);
    const videoContainer = videoContainerRef.current;
    // if(container.classList.contains('scrubbing')) {
    //     container.classList.remove('scrubbing')
    // } else {
    // }
    const video = videoRef.current;
    if (scrubRef.current) {
      // setPlaying(false);
      console.log('video container add before', videoContainer.className);
      // videoContainer.classList.add('scrubbing');
      setIsScrubbing(true);
      console.log('video container add', videoContainer.className);
      wasPaused.current = videoRef.current.paused;
      timeoutRef.current = setTimeout(() => {
        const isPlaying =
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > video.HAVE_CURRENT_DATA;
        if (isPlaying) {
          videoRef.current.pause();
        }
      }, 150);
    } else {
      // setIsScrubbing(false);
      // videoContainer.classList.remove('scrubbing')
      console.log('video container remove', videoContainer);
      videoRef.current.currentTime = percent * duration;
      // setCurrentTime(percent * duration);
      setIsScrubbing(false);
      if (!wasPaused.current) {
        const isPlaying =
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > video.HAVE_CURRENT_DATA;
        if (!isPlaying) {
          videoRef.current.play();
          console.log('play complete');
        }
        // setPlaying(true);
        // wasPaused.current = false;
      }
    }

    handleTimelineUpdate(e);
  };

  const handleScrubClick = () => {
    console.log('click event');
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleScrubbingWrapper = (e) => {
    if (scrubRef.current) handleScrubbing(e);
  };

  const handleTimelineUpdateWrapper = (e) => {
    if (scrubRef.current) handleTimelineUpdate(e);
  };

  const playVideo = () => {
    // setPlaying(true);
    console.log('play event');
    playing.current = true;
  };

  const pauseVideo = () => {
    console.log('pause event');
    playing.current = false;
  };

  function handleKeyDown(e) {
    const tagName = document.activeElement.tagName.toLowerCase();
    const ctrlKey = e.ctrlKey;
    const shiftKey = e.shiftKey;
    if (!open) return;
    if (tagName === 'input') return;
    switch (e.key.toLowerCase()) {
      case ' ':
        if (tagName === 'button') return;
      case 'k':
        togglePlay();
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'f':
        toggleFullScreenMode();
        e.preventDefault();
        e.stopPropagation();
        break;
      case 't':
        toggleTheatreMode();
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'i':
        toggleMiniPlayerMode();
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'm':
        toggleMute();
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'arrowleft':
      case 'j':
        skip(-5);
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'arrowright':
      case 'l':
        if (ctrlKey) {
          skip(30);
        } else {
          skip(5);
        }
        e.preventDefault();
        e.stopPropagation();
        break;
      case 'c':
        toggleCaptions();
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  }

  // useEffect(() => {
  //     let working = false;
  //     const playVideo = async () => {
  //         await videoRef.current.play()
  //         working = false;
  //     };
  //     const pauseVideo = async () => {

  //         await videoRef.current.pause()
  //         working = false;
  //     };
  //     if(playing && !working) {
  //         playVideo();
  //         working = true;
  //     } else if(!working) {
  //         pauseVideo();
  //         working = true;
  //     }
  //     return () => {
  //         working = false;
  //     }
  // }, [playing])

  useEffect(() => {
    // const video = document.getElementById('video');
    // const videoContainer = document.getElementById('video-controls-container');
    const video = videoRef.current;
    const timelineContainer = timelineContainerRef.current;

    // if(!playing) {
    //     console.log('pause called');
    //     video.pause()
    // } else {
    //     console.log('play called');
    //     video.play()
    // }

    // !playing ? video.pause() : video.play();

    // if(currentTime !== )

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('fullscreenchange', toggleFullScreenClass);
    video.addEventListener('play', playVideo);
    video.addEventListener('pause', pauseVideo);
    video.addEventListener('enterpictureinpicture', enterMiniPlayerMode);
    video.addEventListener('leavepictureinpicture', leaveMiniPlayerMode);
    video.addEventListener('loadeddata', setVideoData);
    video.addEventListener('timeupdate', setVideoTime);
    // timelineContainer.addEventListener('mousedown', handleScrubbing);
    // timelineContainer.addEventListener('mousemove', handleTimelineUpdate);
    // video.addEventListener('timeupdate', setVideoTime);
    document.addEventListener('mouseup', handleScrubbingWrapper);
    document.addEventListener('mousemove', handleTimelineUpdateWrapper);

    // console.log('useEffect', video, videoContainer);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('fullscreenchange', toggleFullScreenClass);
      video.removeEventListener('play', playVideo);
      video.removeEventListener('pause', pauseVideo);
      video.removeEventListener('enterpictureinpicture', enterMiniPlayerMode);
      video.removeEventListener('leavepictureinpicture', leaveMiniPlayerMode);
      video.removeEventListener('loadeddata', setVideoData);
      video.removeEventListener('timeupdate', setVideoTime);
      // timelineContainer.removeEventListener('mousedown', handleScrubbing);
      // timelineContainer.removeEventListener('mousemove', handleTimelineUpdate);
      document.removeEventListener('mouseup', handleScrubbingWrapper);
      document.removeEventListener('mousemove', handleTimelineUpdateWrapper);
    };
  });

  console.log('hook render', preview);

  return {
    playing,
    theatreMode,
    fullScreenMode,
    volume,
    duration,
    currentTime,
    captions,
    playbackRate,
    isScrubbing,
    setVolume,
    setCaptions,
    togglePlay,
    toggleTheatreMode,
    toggleFullScreenMode,
    toggleMiniPlayerMode,
    toggleMute,
    toggleCaptions,
    handleVolumeChange,
    changePlaybackSpeed,
    handleTimelineUpdate,
    handleScrubbing,
    handleScrubClick,
  };
};
