import React, { useMemo, useState } from 'react'
import { Avatar, Button, Dropdown, Input, Menu, Modal } from 'antd';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserOutlined from '@ant-design/icons/UserOutlined';

const Header = ({ createBucket, getInfo, deleteBucket, loadBucket }) => {
    const [createModal, setCreateModal] = useState(false);
    const [name, setName] = useState('');

    const items = useMemo(() => {
        return [
            {
                key: '1',
                label: "Logout",
                onClick: () => {
                    localStorage.removeItem('s3-auth')
                    window.location.reload();
                }
            }
        ]
    }, []);

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '2em', padding: '1em' }}>
        <Button onClick={e => setCreateModal(true)}>
            Create Bucket
        </Button>
        <Button onClick={getInfo}>
            Empty Bucket
        </Button>
        <Button onClick={deleteBucket}>
            Delete Bucket
        </Button>
        <Button onClick={e => loadBucket()}>
            Load Bucket
        </Button>
        <Dropdown
            menu={{
                items
            }}
        >
            <Avatar icon={<UserOutlined />} className='profile-dropdown' />
        </Dropdown>
        {/* Create Bucket Modal */}
        <Modal
            open={createModal}
            centered
            closable
            width={500}
            okButtonProps={{ disabled: name.length === 0 }}
            onOk={e => {
                createBucket(name);
                setCreateModal(false);
                setName('');
            }}
            onCancel={e => {
                setCreateModal(false);
                setName('');
            }}
        >
            <h2 style={{ margin: '.5em 0' }}>Create Bucket</h2>
            <Input placeholder="Bucket Name" value={name} onChange={e => setName(e.target.value)} onKeyDown={e => {
                if(e.key === 'Enter') {
                    createBucket(name)
                    setCreateModal(false);
                    setName('')
                }
            }} />
        </Modal>
    </div>
  )
}

export default Header