import { Input, Modal, Radio, Space } from 'antd';
import React, { useState } from 'react'

const CopyModal = ({ open, onClose, selectedBucket, prefix, selectedObject, copyObject }) => {
    const [copyType, setCopyType] = useState("copy");
    const [copyValue, setCopyValue] = useState('');

    const handleCopy = () => {
        copyObject({ copyType, copyValue: copyValue + selectedObject?.replace(prefix, ''), sourceValue: `/${selectedBucket}/${selectedObject}` });
        setCopyType('copy');
        setCopyValue('');
    }

  return (
    <Modal
        open={open}
        onCancel={onClose}
        width={700}
        centered
        closable
        onOk={handleCopy}
      >
        <div style={{ display: 'grid', gap: '1em', padding: '1em' }}>
          <Radio.Group value={copyType} onChange={e => setCopyType(e.target.value)}>
            <Radio.Button value="copy">Copy</Radio.Button>
            <Radio.Button value="move">Move</Radio.Button>
          </Radio.Group>
          <Input readOnly value={`/${selectedBucket}/${selectedObject}`} addonBefore="Source" />
          <Input placeholder='/{bucket-name}/${prefix/}' value={copyValue} onChange={e => setCopyValue(e.target.value)} addonBefore="Destination" addonAfter={typeof selectedObject === 'string' && selectedObject?.replace(prefix, "")} />
        </div>
      </Modal>
  )
}

export default CopyModal