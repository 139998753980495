const config = {
  cloudfrontUrl: "https://cloudfront-s3.rsht123.com/",
//   Local
  // axiosBaseUrl: 'http://192.168.29.14:5000',
// Prod
    // axiosBaseUrl: 'http://172.31.19.43:4000',
    axiosBaseUrl: '',
  fileTypes: [
    'application',
    'audio',
    'font',
    'example',
    'image',
    'message',
    'model',
    'multipart',
    'text',
    'video'
  ]
}


export default config;